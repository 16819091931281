import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    PointPara: '⟶',
    ParaOne:
      'Raindrop provides actionable insights to control spend, both internal and external through rapid access to all the necessary details related to your suppliers, contracts, spend, risk, and compliance.',
    HeadingOne: 'Supply Chain Disruption',
    ParaTwoBefor: 'In the final quarter of 2022, we foresee',
    ParaTwoAnchor: 'turbulence in the global supply chain in 2023.',
    ParaTwoAfter:
      'Mix in port congestion, chip shortages, Russia’s invasion and war on Ukraine, and the long-lingering effects of the COVID-19 pandemic, all simultaneously, and leading economic forecasts clearly demonstrate a constricted global economy well into 2023, if not further. Raindrop is specifically designed and built to provide effective spend management, giving a complete financial picture to fulfil your procurement business processes and mitigate supply chain disruption.',
    AnchorLink: 'https://www.shopify.com/in/enterprise/freight-container-shipping-trends',
    HeadingOnePointOne: 'Dynamic Shipping Costs',
    ParaThree:
      'The world has seen consumers moving swiftly to e-commerce due the pandemic and lockdown restrictions globally. While the shipping containers’ costs were high in 2021 due to the exceptionally high demand in China, and a spike in oil prices around the globe, it has certainly cooled off in 2022 with the current recession. This is where Raindrop’s AI-ML powered Spend Management platform acts as a game changer, to help you proactively avoid supply chain impacts, allowing you to gain complete supplier spend transparency, seek, and manage potential alternate supply channels.',
    HeadingOnePointTwo: 'Meeting Supply Chain Demands',
    ParaFour:
      'Supply chain visibility and data-based analytics is important to forecast demand, and the ability to meet fluctuating demand. For many organizations, this question may sound all to familiar – “Are we forecasting correctly?”.  Surprisingly, many companies of all sizes are still using spreadsheet-driven data reports to draw insights into their analytics. Given the inherent challenges in gaining actionable intelligence from spreadsheets, CPOs are rapidly moving up the value chain via leveraging Raindrop’s powerful Analytics engine, providing access to deep and rapid information to illuminate real-time insights with Raindrop’s easy-to-use and intuitive platform for better decision making across a variety of areas, including forecasting, logistics, supplier risk, and third-party spend.',
    HeadingOnePointThree: 'Contract Management and Compliance',
    ParaFive:
      'To truly understand your third-party commitments, it is critical to proactively manage and review contracts. But how to do that across numerous corporate systems, internal functional towers, and geographies, to name a few? Manual contract management, or multiple system management, can run into the risk of compliance and audit concerns. Because of this, access to actionable insights and intelligence of your contractual obligations may be highly erroneous. Raindrop’s Contract Lifecycle Management (CLM) module creates the environment to enable predictive insights across your entire contract base, from contract renewals, to risk compliance. Raindrop’s Contract Management module also provide a clear 360-degree view to your supplier’s entire contractual footprint, as well as a collaboration framework to chat with your supplier and internal stakeholders about any desired contract document.  Add in Raindrop’s native digital signature integrations, and you’ll start to see a whole new light into significantly increasing third-party contractual obligations from start to finish.',
  },
]
